export const siteInfo = {
    phone: {
        text: '(323) 420-8252',
        link: 'tel:+13234208252',
    },
    email: {
        text: 'connect.wallcoatingsolutions@gmail.com',
        link: 'mailto:connect.wallcoatingsolutions@gmail.com',
    },
    address: {
        text: '1265 W 135th St, Gardena, California, US',
        link: 'https://maps.app.goo.gl/NrQTZDewt9Ca1rCd7',
    },
    social: {
        google: 'https://www.google.com/search?q=1265+W+135th+St%2C+Gardena%2C+California%2C+US+wall+coating+solutions#lrd=0x80c2cbe120fcf13d:0xe3383a25fab9a1c,1,,,,',
    },
};

export type NavLinksTypes = { text: string; link: string; links?: NavLinksTypes }[];

export const navLinks: NavLinksTypes = [
    {
        text: 'about us',
        link: '/about-us/',
    },
    {
        text: 'gallery',
        link: '/gallery/',
    },
];

export const footerNavLinks: NavLinksTypes = [
    {
        text: 'about us',
        link: '/about-us/',
    },
    {
        text: 'gallery',
        link: '/gallery/',
    },
    {
        text: 'privacy policy',
        link: '/privacy-policy/',
    },
];

export function getNavLink(text: string) {
    const linkObj = flatLinks.find(data => data.text.toLowerCase() === text.toLowerCase());

    if (linkObj) return linkObj.link;
    else return '';
}

const flatLinks = (() => {
    const flat: NavLinksTypes = [];

    const pushFlat = (arr: NavLinksTypes) =>
        arr.forEach(data => {
            if (!data.links) flat.push(data);
            else {
                if (data.link) {
                    flat.push({
                        text: data.text,
                        link: data.link,
                    });
                }
                pushFlat(data.links);
            }
        });

    pushFlat(navLinks);
    return flat;
})();
