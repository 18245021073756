import { CLink } from '@/CLink';
import { PlainBtn } from '@/buttons';
import { ReactComponent as ArrowIcon } from '@a/icons/right-arrow.svg';
import styled from '@emotion/styled';
import { HomeLogo } from './Nav';
import { footerNavLinks } from './NavLinks';
import { redTxt, smTxt } from './styles/Typography';
import { flexCenter, flexStart, npx, px } from './styles/classes';

const Section = styled.footer`
    ${px};
    padding-top: 40px;
    padding-bottom: 40px;

    @media (min-width: 768px) {
        padding-bottom: 0;
    }

    @media (min-width: 1024px) {
        display: grid;
        padding-top: 0;
        gap: 64px;
        row-gap: 0;
        max-width: 1905px;
        margin: 0 auto;
        align-items: center;
        grid-template-columns: 55% 1fr;
        grid-template-areas:
            'a b'
            'd d';
    }
`;

const FirstBox = styled.div`
    margin-bottom: 32px;
    max-width: 415px;
    grid-area: a;
    justify-self: end;

    > p {
        ${smTxt};
        font-weight: 500;

        :first-of-type {
            margin: 24px 0 16px;
        }

        :last-of-type {
            margin: 0;

            span {
                display: block;
                margin: 8px 0 0;
            }
        }
    }

    @media (min-width: 1024px) {
        grid-area: a;
        max-width: unset;
        margin-bottom: 0;
        padding: 40px 64px 64px 0;
        border-right: 1px solid ${({ theme }) => theme.colors.gray3};
    }
`;

const Links = styled.div`
    ${flexStart};
    margin-bottom: 32px;
    flex-direction: column;

    > h5 {
        margin: 0 0 16px;
        color: ${({ theme }) => theme.colors.gray4};
    }

    > a {
        transition: color 0.3s ease-in-out;
        margin-top: 16px;
        text-transform: capitalize;

        :hover,
        :focus {
            color: ${({ theme }) => theme.colors.blue3};
        }
    }

    @media (min-width: 1024px) {
        grid-area: b;
        margin-bottom: 0;
        justify-self: start;
    }
`;

const Bottom = styled.div`
    ${flexCenter};
    flex-direction: column;
    align-items: stretch;
    margin-top: 48px;
    border-top: 1px solid ${({ theme }) => theme.colors.gray3};

    > button {
        margin: 0 -16px;
        background-color: ${({ theme }) => theme.colors.gray7};
        padding: 21px;

        > svg {
            transform: rotate(-90deg);
        }
    }

    > p {
        ${redTxt};
        color: ${({ theme }) => theme.colors.gray4};
        margin: 24px 0 0;
        text-align: center;
        font-weight: 500;
    }

    @media (min-width: 768px) {
        ${npx};
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-left: ${({ theme }) => theme.spacing.md};

        > p {
            text-align: left;
            order: -1;
            margin: 0;
        }

        > button {
            margin: 0;
        }
    }

    @media (min-width: 1024px) {
        grid-area: d;
        margin-top: 0;
        justify-self: stretch;
    }
`;

export const Footer = () => {
    return (
        <Section>
            <FirstBox>
                <HomeLogo />
                <p>
                    Personalized solutions turning your vision into reality with unparalleled
                    craftsmanship.
                </p>
                <p>
                    Office Timings: <span />
                    Monday - Friday, 7:00 AM - 4:30 PM
                </p>
            </FirstBox>
            <Links>
                <h5>Wall Coating Solutions</h5>
                {footerNavLinks.map((data, i) => (
                    <CLink key={i} to={data.link}>
                        {data.text}
                    </CLink>
                ))}
            </Links>

            <Bottom>
                <PlainBtn
                    onClick={() => {
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        });
                    }}
                >
                    <ArrowIcon />
                </PlainBtn>
                <p>© 2024 Wall Coating Solutions. All rights reserved</p>
            </Bottom>
        </Section>
    );
};
