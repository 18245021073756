import { CLink } from '@/CLink';
import { Dialog, DialogClose, DialogContent, DialogOpen } from '@/DialogPrimitive';
import { PlainBtn, PrimaryBtn } from '@/buttons';
import { ReactComponent as ChevronIcon } from '@a/icons/chevron.svg';
import { ReactComponent as CloseIcon } from '@a/icons/close.svg';
import { ReactComponent as MenuIcon } from '@a/icons/menu.svg';
import { ReactComponent as BackIcon } from '@a/icons/right-arrow-circle-sm.svg';
import { ReactComponent as RightArrowIcon } from '@a/icons/right-arrow.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useLocationData from '@s/hooks/useLocationData';
import useRouteChange from '@s/hooks/useRouteChange';
import useWindowSize from '@s/hooks/useWindowSize';
import { Fragment, useEffect, useState } from 'react';
import { HomeLogo } from './Nav';
import { navBtnStyles, navLinkStyles } from './NavLinkStyles';
import { NavLinksTypes, navLinks } from './NavLinks';
import { theme } from './styles/GlobalStyles';
import { flexCenter, flexSpace, px } from './styles/classes';

const overlayStyles = css`
    display: none;
`;

const contentStyles = css`
    background-color: ${theme.colors.gray1};
    padding-top: 16px;
    padding-bottom: 40px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    > nav {
        ${flexCenter};
        ${px};
        align-items: stretch;
        text-align: center;
        flex-direction: column;
        margin: 0 auto;
        max-width: max-content;
        overflow-y: auto;
        padding-right: 10px;
        height: calc(100% - 67px); // minus top bar

        > a {
            :first-of-type {
                margin-top: auto;
            }

            :last-of-type {
                margin-bottom: auto;
            }
        }
    }

    &[data-state='open'] {
        animation: slideIn 300ms ease-in;
    }

    &[data-state='closed'] {
        animation: slideOut 300ms ease-out;
    }

    @keyframes slideIn {
        from {
            transform: translateX(100vw);
        }
        to {
            transform: translateX(0);
        }
    }

    @keyframes slideOut {
        from {
            transform: translateX(0);
        }
        to {
            transform: translateX(100vw);
        }
    }
`;

const Top = styled.div`
    ${px};
    ${flexSpace};
    padding-bottom: 16px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray3};

    > button {
        :last-of-type {
            margin-left: auto;
        }
    }
`;

type MobNavProps = {
    text?: string;
    links?: NavLinksTypes;
    allDialogsClosed: boolean;
    closeAllDialogs: () => void;
};

export const MobNav = ({
    text,
    links = navLinks,
    allDialogsClosed,
    closeAllDialogs,
}: MobNavProps) => {
    const [open, setOpen] = useState(false);

    const { width } = useWindowSize();

    useRouteChange(setOpen);

    const { title, category } = useLocationData();

    useEffect(() => {
        setOpen(false);
    }, [allDialogsClosed]);

    const currentCategory = category === text || title === text;

    return width < 1280 ? (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogOpen>
                {text ? (
                    <PlainBtn
                        css={navBtnStyles}
                        className={currentCategory ? 'current-nav-category' : ''}
                    >
                        {text} <ChevronIcon />
                    </PlainBtn>
                ) : (
                    <PlainBtn
                        aria-label="open navigation menu"
                        css={css`
                            @media (min-width: 1280px) {
                                display: none;
                            }
                        `}
                    >
                        <MenuIcon />
                    </PlainBtn>
                )}
            </DialogOpen>

            <DialogContent
                contentStyles={contentStyles}
                overlayStyles={overlayStyles}
                title="mobile nav menu"
            >
                <Top>
                    {text ? (
                        <DialogClose>
                            <PlainBtn aria-label="close sub nav menu">
                                <BackIcon />
                            </PlainBtn>
                        </DialogClose>
                    ) : (
                        <HomeLogo />
                    )}

                    <PlainBtn onClick={closeAllDialogs} aria-label="close nav menu">
                        <CloseIcon />
                    </PlainBtn>
                </Top>

                <nav>
                    {links.map((data, i) =>
                        data.links ? (
                            <MobNav
                                text={data.text}
                                links={data.links}
                                key={i}
                                allDialogsClosed={allDialogsClosed}
                                closeAllDialogs={closeAllDialogs}
                            />
                        ) : (
                            <CLink to={data.link} css={navLinkStyles} key={i}>
                                {data.text}
                            </CLink>
                        )
                    )}
                    <PrimaryBtn as={CLink} to="/contact-us/">
                        Contact Us <RightArrowIcon />
                    </PrimaryBtn>
                </nav>
            </DialogContent>
        </Dialog>
    ) : (
        <Fragment />
    );
};
