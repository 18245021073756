import { CLink } from '@/CLink';
import { ReactComponent as EmailIcon } from '@a/icons/email.svg';
import { ReactComponent as AddressIcon } from '@a/icons/map.svg';
import { ReactComponent as PhoneIcon } from '@a/icons/phone.svg';
import styled from '@emotion/styled';
import { ContactForm } from './ContactForm';
import { siteInfo } from './NavLinks';
import { smTxt } from './styles/Typography';
import { flexCenter, npx, px, py } from './styles/classes';

const Section = styled.section`
    ${py};
    ${px};
    border-top: 1px solid ${({ theme }) => theme.colors.gray3};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray3};

    @media (min-width: 1280px) {
        ${flexCenter};
        gap: 90px;
    }
`;

const Box = styled.div`
    ${px};
    ${npx};
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 40px;
    background-color: ${({ theme }) => theme.colors.gray2};
    max-width: 620px;

    > h3 {
        margin: 0;
    }

    > p {
        margin: 24px 0 40px;
    }

    > a {
        display: flex;
        align-items: center;
        gap: 16px;
        max-width: max-content;

        :not(:last-of-type) {
            margin-bottom: 40px;
        }

        > span {
            ${flexCenter};
            width: 56px;
            height: 56px;
            background-color: ${({ theme }) => theme.colors.red1};
            transition: background-color 0.3s ease-in-out;
            flex-shrink: 0;

            > svg {
                path {
                    fill: ${({ theme }) => theme.colors.red2};
                    transition: fill 0.3s ease-in-out;
                }
            }
        }

        > p {
            margin: 0;

            > span {
                display: block;
                transition: color 0.3s ease-in-out;

                :first-of-type {
                    ${smTxt};
                    color: ${({ theme }) => theme.colors.gray5};
                    font-weight: 600;
                }
            }
        }

        :hover,
        :focus {
            > span {
                background-color: ${({ theme }) => theme.colors.blue1};

                > svg path {
                    fill: ${({ theme }) => theme.colors.blue3};
                }
            }

            > p > span {
                :first-of-type {
                    color: ${({ theme }) => theme.colors.blue2};
                }

                :last-of-type {
                    color: ${({ theme }) => theme.colors.blue3};
                }
            }
        }
    }

    @media (min-width: 1280px) {
        margin: 0 !important;
    }
`;

export const ContactUs = () => {
    return (
        <Section>
            <Box>
                <h3>Contact Info</h3>
                <p>
                    Talk to us today at Wall Coating Solutions.
                    <br />
                    General inquires? Have a new Bid?
                </p>
                <CLink to={siteInfo.address.link}>
                    <span>
                        <AddressIcon />
                    </span>
                    <p>
                        <span>First Office Address</span>
                        <span>{siteInfo.address.text}</span>
                    </p>
                </CLink>
                <CLink to={siteInfo.phone.link}>
                    <span>
                        <PhoneIcon />
                    </span>
                    <p>
                        <span>Office Number</span>
                        <span>{siteInfo.phone.text}</span>
                    </p>
                </CLink>
                <CLink to={siteInfo.email.link}>
                    <span>
                        <EmailIcon />
                    </span>
                    <p>
                        <span>Email Address</span>
                        <span>{siteInfo.email.text}</span>
                    </p>
                </CLink>
            </Box>
            <ContactForm />
        </Section>
    );
};
